export function useStripeCustomerPortal() {
  const url = ref('');

  async function execute() {
    const data = await $fetch('/api/stripe/customer-portal');
    url.value = data;
  }

  execute();

  return url;
}
