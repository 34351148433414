import type { Database } from '@/types/database.types';

export function useSubscription() {
  const supabase = useSupabaseClient<Database>();

  const { data: subscription } = useAsyncData('subscription', async () => {
    const { data: profile } = await supabase
      .from('profiles')
      .select('customer_id')
      .single();
    const { data, error } = await supabase
      .schema('stripe')
      .from('subscriptions')
      .select(
        'id, customer, status: attrs->status, current_period_end: attrs->current_period_end, amount:attrs->plan->amount, attrs->plan->currency, attrs->plan->interval, attrs->plan->product, attrs->items->data->0->price->lookup_key',
      )
      .eq('customer', profile?.customer_id || '')
      .maybeSingle();

    if (error) {
      console.error(error);
    }

    return data;
  });

  return subscription;
}
