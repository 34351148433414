import type { Database } from '@/types/database.types';

export function useProfile() {
  const supabase = useSupabaseClient<Database>();

  return useAsyncData('profile', async () => {
    const { data } = await supabase.from('profiles').select('*').single();

    return data;
  });
}
